import { Application } from "@hotwired/stimulus"
import Dropdown from '@stimulus-components/dropdown'

const application = Application.start()

// Import and register all TailwindCSS Components
import { Slideover, Tabs } from "tailwindcss-stimulus-components"
application.register('slideover', Slideover)
application.register('tabs', Tabs)
application.register('dropdown', Dropdown)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
