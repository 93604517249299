import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean, toggleKey: String }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    this.openValue = !this.openValue
  }

  save(event) {
    console.log("save", this.toggleKeyValue)

    // update server
    let url = `/user/toggles/${this.toggleKeyValue}`

    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.csrf_token()
      }
    })
  }

  hide(event) {
    event.preventDefault();

    this.openValue = false;
  }

  show(event) {
    event.preventDefault();

    this.openValue = true;
  }

  openValueChanged() {
    console.log("openValueChanged", this.openValue, this.toggleableTargets)
    if (!this.toggleClass) { return }

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
  }

  csrf_token() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
}